import React from 'react';
import underline from './../../assets/oursevices_cardunderline.png';
import './ServicesCard.scss';

function ServicesCard({className, title, bg_img, paragraph, quote}) {
  return (
    <div className={`ourservices_wrapper_card ${className}`} >
        {/*<div className={`ourservices_wrapper_card_blueboxtopvertical ${className}`}></div>
        <div className={`ourservices_wrapper_card_blueboxtophorizontal ${className}`} ></div>*/}
        <div className='ourservices_wrapper_card_backgroundimage' style={{backgroundImage: `url(${bg_img})`}} ></div>
        <h3 className='ourservices_wrapper_card_title' >{title}</h3>
        {/* <img src={underline} className='ourservices_wrapper_card_underline' /> */}
        <p className='ourservices_wrapper_card_paragraph' >{paragraph}</p>
        <h4 className='ourservices_wrapper_card_quote' >{quote}</h4>
        {/*<div className={`ourservices_wrapper_card_blueboxbottomvertical ${className}`} ></div>
        <div className={`ourservices_wrapper_card_blueboxbottomhorizontal ${className}`} ></div>*/}
    </div>
  )
}

export default ServicesCard