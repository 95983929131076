import React from 'react';
import './IndustrialRevolution.scss';
import { useState } from 'react';

function IndustrialRevolution() {
    const [isopen, setIsOpen] = useState(1);
    function handleClick(number){
        console.log(number);
        setIsOpen(number);
    }
  return (
    <div className='ir_wrapper'>
        <div className='ir_wrapper_heading'>
            <h1 className='ir_wrapper_heading_text'>Industrial revolution</h1>
        </div>
        <div className='ir_wrapper_backgroundimage'></div>
        <div className='ir_wrapper_contents'>
            <div className='ir_wrapper_contents_text'>
                <h1 className='ir_wrapper_contents_text_heading'>Industrial revolution</h1>
                <p className='ir_wrapper_contents_text_intropara'>                </p>
                <div className='ir_wrapper_contents_text_stages'>
                    <div className='ir_wrapper_contents_text_stages_one' >
                        <div className='ir_wrapper_contents_text_stages_one_title'>
                            <div className={'ir_wrapper_contents_text_stages_one_title_openicon '+ (isopen===1?' ':'closed_icon')}>
                                <button className='openicon_button' onClick={() => {handleClick(1)}}></button>
                            </div>
                            <h3 className={'ir_wrapper_contents_text_stages_one_title_text '+ (isopen===1?'opentitle':' ')} onClick={() => {handleClick(1)}}>The first industrial revolution</h3>
                        </div>
                        <p className={'ir_wrapper_contents_text_stages_one_title_text_paragraph '+ (isopen===1?' ':'closed')}>From the wake of the Industrial revolution in the 18th century, human civilization has been advancing at an increasingly rapid pace. We are considered to be in the fourth industrial revolution. Following a brief description of all the industrial revolutions.
                        </p>
                    </div>
                    <div className='ir_wrapper_contents_text_stages_two' >
                        <div className='ir_wrapper_contents_text_stages_two_title'>
                            <div className={'ir_wrapper_contents_text_stages_two_title_openicon '+ (isopen===2?' ':'closed_icon')}>
                                <button className='openicon_button' onClick={() => {handleClick(2)}}></button>
                            </div>
                            <h3 className={'ir_wrapper_contents_text_stages_two_title_text '+ (isopen===2?'opentitle':' ')} onClick={() => {handleClick(2)}}>The second industrial revolution</h3>
                        </div>
                        <p className={'ir_wrapper_contents_text_stages_two_title_text_paragraph '+ (isopen===2?' ':'closed')}>The Second Industrial Revolution 19th and 20th century: Widespread adoption of electricity, the internal combustion engine, and the telephone. These innovations led to the creation of new industries such as automobiles, chemicals, and steel. The second industrial revolution also saw the rise of mass production and the development of business practices such as the assembly line and scientific management.</p>
                    </div>
                    <div className='ir_wrapper_contents_text_stages_three' >
                        <div className='ir_wrapper_contents_text_stages_three_title'>
                            <div className={'ir_wrapper_contents_text_stages_three_title_openicon '+(isopen===3?' ':'closed_icon')}
                            onClick={() => {handleClick(3)}}></div>
                            <h3 className={'ir_wrapper_contents_text_stages_three_title_text '+ (isopen===3?'opentitle':' ')} onClick={() => {handleClick(3)}}>The third industrial revolution</h3>
                        </div>
                        <p className={'ir_wrapper_contents_text_stages_three_title_text_paragraph '+ (isopen===3?' ':'closed')}>Digital Revolution or the Information Age . Key developments include the development of the internet, personal computers, and mobile devices. These changes have transformed the way people live, work, and communicate, and have led to the creation of new industries such as information technology and e-commerce.</p>
                    </div>
                    <div className='ir_wrapper_contents_text_stages_four' >
                        <div className='ir_wrapper_contents_text_stages_four_title'>
                            <div className={'ir_wrapper_contents_text_stages_four_title_openicon '+(isopen===4?' ':'closed_icon')}
                            onClick={() => {handleClick(4)}}></div>
                            <h3 className={'ir_wrapper_contents_text_stages_four_title_text '+ (isopen===4?'opentitle':' ')} onClick={() => {handleClick(4)}}>The fourth industrial revolution</h3>
                        </div>
                        <p className={'ir_wrapper_contents_text_stages_four_title_text_paragraph '+ (isopen===4?' ':'closed')}>The Fourth indsutsrial revolution is considered the current industrial revolution. The main technologies driving this revolution include Artificial intelligence, Internet of Things, Robotics, 3D printing and VR / AR. It has only begun in the 21st Century and has really accelerated the past 5-10 years.</p>
                    </div>
                </div>
            </div>
            <div className='ir_wrapper_contents_backgroundimage'></div>
        </div>
        <div className='ir_wrapper_contents_foregroundimages'>
            <div className={'ir_wrapper_contents_foregroundimages_first ' +(isopen===1?'top':' ') } onClick={() => {handleClick(1)}}></div>
            <div className={'ir_wrapper_contents_foregroundimages_second ' +(isopen===2?'top':' ')} onClick={() => {handleClick(2)}}></div>
            <div className={'ir_wrapper_contents_foregroundimages_third '+(isopen===3?'top':' ')} onClick={() => {handleClick(3)}}></div>
            <div className={'ir_wrapper_contents_foregroundimages_fourth '+(isopen===4?'top':' ')} onClick={() => {handleClick(4)}}></div>
        </div>
    </div>
  )
}

export default IndustrialRevolution