import React from 'react';
import Navbar_Img from './../../assets/navbar_icon.png';
import Menu_Img from './../../assets/menu_icon.png';
import './NavigationBar.scss';
import { useState, useRef } from 'react';

function NavigationBar({setRef, timeline, home, contactus, services, effectTrigger, setEffectTrigger}) {
  const [isClicked, setIsClicked] = useState(false);
  const windowWidth = useRef(window.innerWidth);
  let mobileMenu = false;
  const handleClick = () => {
    setIsClicked(!isClicked);
    console.log('cliked');
  }

  if ((isClicked == true) && (windowWidth.current<500)) {
    mobileMenu = true;
  }

  return (
    <div className='navigationbar_wrapper'>
        <div className='navigationbar_wrapper_icon'>
            <img src={Navbar_Img} />
        </div>
        <div className={ (isClicked)?'navigationbar_wrapper_buttonsmobile':'navigationbar_wrapper_buttons'}>
            <button className='navigationbar_wrapper_buttons_home' onClick={() => {
              setEffectTrigger(effectTrigger+1);
              setRef(home);}}>What we do</button>
            <button className='navigationbar_wrapper_buttons_services' onClick={() => {
              setEffectTrigger(effectTrigger+1);
              setRef(services);}}>Who we are</button>
            <button className='navigationbar_wrapper_buttons_timeline' onClick={() => {
              setEffectTrigger(effectTrigger+1);
              setRef(timeline);}}>Why IJS Capital</button>
            <button className='navigationbar_wrapper_buttons_timeline' onClick={() => {
              setEffectTrigger(effectTrigger+1);
              setRef(timeline);}}>Careers</button>
            <button className='navigationbar_wrapper_buttons_contactus' onClick={() => {
              setEffectTrigger(effectTrigger+1);
              setRef(contactus);}}>Contact us</button>
        </div>
        <div className='navigationbar_wrapper_menuicon'>
          <img src={Menu_Img} onClick={handleClick} />
        </div>
    </div>
  )
}

export default NavigationBar