import React from 'react';
import './FocusAreaCard.scss';
import underline from './../../assets/oursevices_cardunderline.png'

function FocusAreaCard({title, bg_img, paragraph, direction}) {
  let opposite_direction = direction === 'forward' ? 'reverse' : 'forward'
  return (
    <div className={`focusareacard_wrapper ${direction}`}>
        <div className='focusareacard_wrapper_image' style={{backgroundImage: `url(${bg_img})`}}></div>
        <div className='focusareacard_wrapper_content'>
            <div className={`focusareacard_wrapper_content_title ${opposite_direction}`}>
                <h4 className='focusareacard_wrapper_content_title_text'>{title}</h4>
                {/* <img src={underline} className='focusareacard_wrapper_content_title_underline'/> */}
            </div>
            <div className='focusareacard_wrapper_content_paragraph' >
                <p className='focusareacard_wrapper_content_paragraph_text'>{paragraph}</p>
            </div>
        </div>
    </div>
  )
}

export default FocusAreaCard