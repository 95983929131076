import './App.css';
import FocusAreas from './components/FocusAreas/FocusAreas';
import Footer from './components/Footer/Footer';
import GetInTouch from './components/GetInTouch/GetInTouch';
import HeroSection from './components/HeroSection/HeroSection';
import IndustrialRevolution from './components/IndustrialRevolution/IndustrialRevolution';
import MissionStatement from './components/MissionStatement/MissionStatement';
import NavigationBar from './components/NavigationBar/NavigationBar';
import OurServices from './components/OurServices/OurServices';
import Timeline from './components/Timeline/Timeline';

import { useEffect, useRef, useState } from 'react';

function App() {

  const timeline = useRef(null);
  const home = useRef(null);
  const services = useRef(null);
  const contactus = useRef(null);
  const [componentRef, setComponentRef] = useState(home);
  const [effectTrigger, setEffectTrigger] = useState(0);

  useEffect(() => {
    console.log(componentRef);
    console.log(effectTrigger);
    window.scrollTo({
      top: componentRef.current?.offsetTop,
      behavior: 'smooth',
    });
  }, [effectTrigger])

  return (
    <div className="App">
      <NavigationBar setRef={setComponentRef} setEffectTrigger={setEffectTrigger} effectTrigger={effectTrigger} timeline={timeline} home={home} services={services} contactus={contactus}/>
      <HeroSection ref={home}/>
      <Timeline ref={timeline}/>
      <MissionStatement />
      <IndustrialRevolution />
      <OurServices ref={services} />
      <FocusAreas />
      <GetInTouch ref={contactus}/>
      <Footer />
    </div>
  );
}

export default App;
