import React, { forwardRef } from 'react';
import './OurServices.scss';
import ServicesCard from '../ServicesCard/ServicesCard';
import { useState, useEffect, useRef, useCallback } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import InvestingImage from './../../assets/riccardo-annandale-7e2pe9wjL9M-unsplash.jpg';
import TradingImage from './../../assets/piggybank-CBbxr3JgCoo-unsplash.jpg';
import ConsultingImage from './../../assets/scott-graham-5fNmWej4tAA-unsplash.jpg';
import IotImage from './../../assets/dan-lefebvre-RFAHj4tI37Y-unsplash.jpg';
import SAPConsultingImage from './../../assets/gatis-marcinkevics-a5uptAdUmjE-unsplash.jpg';


const titles = ['IoT and Web Applications Development','Consultancy Services','Investment Services','Trading Services','SAP Basis and SAP HANA'];
const paragraphs = [
     'Capitalize on the power of digital connectivity with our IoT and Web Applications Development services. Our skilled developers can design and build intuitive, user-friendly applications that seamlessly connect devices, collect data, and automate processes. We\'ll help you leverage the full potential of the Internet of Things to streamline your operations and deliver enhanced customer experiences.',
     'Our seasoned consultants bring a wealth of experience to help you solve business challenges, streamline operations, and improve efficiency. Leveraging our deep industry knowledge, we provide customized strategies and actionable insights that drive growth and competitiveness.',
     'Invest in a cleaner, greener future with our environmentally-conscious investment services. We curate sustainable investment opportunities that don\'t just promise good returns but also contribute to a healthier planet. With us, you can grow your wealth while making a positive impact.',
     'Navigate the dynamic world of trading with our professional trading services. Our expert team can help you strategize, execute trades, and manage risk, whether you\'re trading commodities, equities, or currencies. With our advanced platforms and personalized support, we make trading simpler, safer, and more profitable.',
     'Master the complexities of your SAP landscape with our SAP Basis and SAP HANA services. Our certified experts with more than 30years of experience ensure that your system is running smoothly, securely, and at peak performance. We offer comprehensive management solutions including installation, configuration, monitoring, and optimization to free up your team\'s time and resources.'
     ]
const quotes = [
     '\'Let\'s build your digital future together. Contact us to get started!\'',
     '\'Ready to unlock your business potential? Schedule a free consultation today!\'',
     '\'Start investing in a green future now. Get in touch to learn more about our sustainable investment opportunities.\'',
     '\'Navigate the world of trading with our expert guidance. Register for our trading platform today\'',
     '\'Ensure your SAP system is at peak performance. Contact our SAP experts today!\''
]

const bg_imgs = [
     IotImage, ConsultingImage, InvestingImage, TradingImage, SAPConsultingImage,
 ]
function OurServices({}, ref) {
     const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

     useEffect(() => {
          window.addEventListener("resize", () => {
              const ismobile = window.innerWidth < 500;
              if (ismobile !== isMobile) setIsMobile(ismobile);
          }, false);
      }, [isMobile]);

     const [active, setActive] = useState(2);
     const [actives, setActives] = useState([1,2,3]);
     const [bubble, setBubble] = useState(2);

     const timerRef = useRef(null);

     const goToNext = useCallback(() => {
          const isLastCard = active === 4;
          const isfirstBubble = bubble === 1;
          const newIndex = isLastCard ? 0 : active + 1;
          const newBubble = isfirstBubble ? 3: bubble - 1;
          const newIndices = actives;
          newIndices[0] = (actives[0] === 4) ? 0 : actives[0]+1;
          newIndices[1] = (actives[1] === 4) ? 0 : actives[1]+1;
          newIndices[2] = (actives[2] === 4) ? 0 : actives[2]+1;
          setActive(newIndex);
          setActives(newIndices);
          setBubble(newBubble);
        }, [active, actives]);

     useEffect(() => {
     if (timerRef.current) {
          clearTimeout(timerRef.current);
     }
     timerRef.current = setTimeout(() => {
          goToNext();
     }, 4000);
     
     return () => clearTimeout(timerRef.current);
     }, [goToNext]);

     
     const handleClick = (number) => {
          if (!isMobile) {
               if (number === 1) {
                    setActives([0,1,2]);
                    setBubble(1);
               }
     
               if (number === 2){
                    setActives([1,2,3]);
                    setBubble(2);
               }
     
               if (number === 3){
                    setActives([2,3,4]);
                    setBubble(3);
               }
          }

          if (isMobile){
               if (number === 11){
                    setActive(0);
               }
               if (number === 22){
                    setActive(1);
               }
               if (number === 33){
                    setActive(2);
               }
               if (number === 44){
                    setActive(3);
               }
               if (number === 55){
                    setActive(4);
               }
          }

          console.log(number);
          console.log(active);
          console.log(actives);
     }
  return (
    <div className='ourservices_wrapper' ref={ref}>
        <div className='ourservices_wrapper_heading' >
            <h1 className='ourservices_wrapper_heading_text' >Products and solutions</h1>
        </div>
        <div className='ourservices_wrapper_intropara' >
            <p className='ourservices_wrapper_intropara_text' >Our offers professional investment and consulting services delivered by experienced experts in the field. We provide guidance and strategic advice to help you make informed decisions and achieve your financial goals.</p>
        </div>
        <div className='ourservices_wrapper_cards' > {(isMobile)? 
          <ServicesCard title={titles[active]} bg_img={bg_imgs[active]} paragraph={paragraphs[active]} quote={quotes[active]} className={'active'}/>
        :<><ServicesCard title={titles[actives[0]]} bg_img={bg_imgs[actives[0]]} paragraph={paragraphs[actives[0]]} quote={quotes[actives[0]]} className={'active'}/>
          <ServicesCard title={titles[actives[1]]} bg_img={bg_imgs[actives[1]]} paragraph={paragraphs[actives[1]]} quote={quotes[actives[1]]} className={'active'}/>
          <ServicesCard title={titles[actives[2]]} bg_img={bg_imgs[actives[2]]} paragraph={paragraphs[actives[2]]} quote={quotes[actives[2]]} className={'active'}/></>
        }</div>
        <div className='ourservices_wrapper_bubbles' >
          <div className={`ourservices_wrapper_bubbles_shape_desktop ${(bubble===1)?'active':'inactive'}`} onClick={() => {handleClick(1)}} ></div>
          <div className={`ourservices_wrapper_bubbles_shape_desktop ${(bubble===2)?'active':'inactive'}`} onClick={() => {handleClick(2)}}></div>
          <div className={`ourservices_wrapper_bubbles_shape_desktop ${(bubble===3)?'active':'inactive'}`} onClick={() => {handleClick(3)}}></div>
          <div className={`ourservices_wrapper_bubbles_shape_mobile ${(active===0)?'active':'inactive'}`} onClick={() => {handleClick(11)}}></div>
          <div className={`ourservices_wrapper_bubbles_shape_mobile ${(active===1)?'active':'inactive'}`} onClick={() => {handleClick(22)}}></div>
          <div className={`ourservices_wrapper_bubbles_shape_mobile ${(active===2)?'active':'inactive'}`} onClick={() => {handleClick(33)}}></div>
          <div className={`ourservices_wrapper_bubbles_shape_mobile ${(active===3)?'active':'inactive'}`} onClick={() => {handleClick(44)}}></div>
          <div className={`ourservices_wrapper_bubbles_shape_mobile ${(active===4)?'active':'inactive'}`} onClick={() => {handleClick(55)}}></div>
        </div>
    </div>
  )
}

export default forwardRef(OurServices);