import React from 'react';
import imageBelowTheText from './../../assets/timelineImages_mainTitle.png';
import './MissionStatement.scss';

function MissionStatement() {
  return (
    <div className='wrapper' >
        <div className='wrapper_mobile'>
            <h1 className='wrapper_mobile_heading'>Mission statement</h1>
        </div>
        <div className='missionstatement_wrapper'>
            <div className='missionstatement_wrapper_backgroundimage' ></div>
            <div className='missionstatement_wrapper_contentcontainer' >
                <div className='missionstatement_wrapper_contentcontainer_image'></div>
                <div className='missionstatement_wrapper_contentcontainer_text'>
                    <h1 className='missionstatement_wrapper_contentcontainer_text_heading'>
                        Mission statement
                    </h1>
                    <p className='missionstatement_wrapper_contentcontainer_text_paragraph'>
                        IJS Capital is an investment firm that is committed to investing and building innovative solutions for businesses that aim to achieve both carbon neutrality and Industry 4.0 readiness. <br/><br/>
                        By leveraging advanced technologies such as artificial intelligence, Internet of things, automation, and renewable energy sources, we help our clients to optimize their operations, reduce their environmental impact, and stay ahead of the curve in the rapidly evolving digital landscape. <br /><br/>
                        Our goal is to provide our clients with the tools and expertise they need to thrive in the new era of sustainable, tech-driven industry, while also contributing to a cleaner, healthier planet for future generations. <br /><br/>
                    </p>
                    <img src={imageBelowTheText} className='missionstatement_wrapper_contentcontainer_text_image' />
                </div>
            </div>

        </div>
    </div>
  )
}

export default MissionStatement