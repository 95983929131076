import React from 'react';
import './Footer.scss';
import logo from './../../assets/navbar_icon.png';

function Footer() {
  return (
    <div className='footer'>
        <div className='footer_buttons'>
            <div className='footer_buttons_company' >
                <div className='footer_buttons_company_logo' ></div>
                <button className='footer_buttons_company_ijs' >IJSCapital.com</button>
                <button className='footer_buttons_company_services' >SERVICES</button>
                <button className='footer_buttons_company_contactus' >Contact us</button>
                <button className='footer_buttons_company_privacy' >Privacy policy</button>
                <button className='footer_buttons_company_data' >Data handling</button>
            </div>
            <div className='footer_buttons_socialmedia' >
                <div className='footer_buttons_socialmedia_in' ></div>
                <div className='footer_buttons_socialmedia_fb' ></div>
                <div className='footer_buttons_socialmedia_instagram' ></div>
            </div>
        </div>
        <div className='footer_copyrights' style={{display:"grid",gridTemplateColumns:"1fr 1fr",justifyItems:"center"}} >
            <div>© 2023 IJS Capital APS All Rights Reserved | CVR: 38986309</div>
            <div></div>
        </div>
    </div>
  )
}

export default Footer
