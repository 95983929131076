import React from 'react';
import './FocusAreas.scss';
import FocusAreaCard from '../FocusAreaCard/FocusAreaCard';
import AgricultureImage from './../../assets/zoe-schaeffer-tbiV-yc903g-unsplash.jpg';
import EnergyImage from './../../assets/nicholas-doherty-pONBhDyOFoM-unsplash.jpg';
import FashionImage from './../../assets/alyssa-strohmann-TS--uNw-JqE-unsplash.jpg';
import PackagingImage from './../../assets/daily-nouri-E5Oo4nENq2M-unsplash.jpg';
import OceansImage from './../../assets/gatis-marcinkevics-a5uptAdUmjE-unsplash.jpg';


const titles = [
    'Agricultural sector', 'Energy sector', 'Fast Fashion', 'Packaging', 'The Oceans',
]

const paragraphs = [
    'The US EPA estimates that 24% of global greenhouse gas emissions are from the agricultural sector, resulting in a loss of ecosystems and 150-200 animal and plant species going extinct every day. Solutions include vertical and indoor farms to increase output, reduce crop susceptibility to pests, and reduce food waste and transportation. Carbon taxes, such as per kilo CO2 emissions on meat products, could help reduce meat consumption.',
    'The energy sector accounts for 25% of global emissions, mostly due to energy production and heating/cooling. Smart solutions such as sensors and home automation could save energy consumption. Transportation accounts for 14% of global emissions, with planes, cars and trucks being the worst polluters. Rail is the most carbon efficient mode of transport, with electric, hybrid and hydrogen fuel cell drive trains. Technology is moving in the right direction, but research still lacks hydrogen fuelled ships and trucks.',
    'The production of fast fashion relies on the intensive use of natural resources such as water, energy, and raw materials, and generates significant amounts of waste and pollution. This includes the release of greenhouse gasses during production and transportation, the use of toxic chemicals in textile production, and the disposal of vast amounts of clothing in landfills or incineration, contributing to the global environmental crisis. Use of material that is recyclable and biodegradable Is paramount to the industry.',
    'Single-use plastic items such as bags, straws, and food packaging contribute to plastic waste that can take hundreds of years to decompose, harming wildlife and ecosystems. The production of plastic also relies on non-renewable resources and generates greenhouse gas emissions. To address the plastic and packaging pollution crisis, a shift towards more sustainable and circular systems is needed. Home delivery has increased the carbon footprint.',
    'The absorption of CO2 by the oceans has led to a process known as ocean acidification. This has had a range of impacts on marine flora and fauna, including Coral bleaching, reduced growth rates and reproductive success in some species, and increased mortality rates in others. In addition the warming of oceans is leading to changes in ocean currents, sea levels, and weather patterns, which can have a range of impacts on marine ecosystems, including altered migration patterns, changes in feeding habits, and the spread of harmful algal blooms.',
]

const bg_imgs = [
    AgricultureImage, EnergyImage, FashionImage, PackagingImage, OceansImage,
]

function FocusAreas() {
  return (
    <div className='focusareas_wrapper'>
        <h1 className='focusareas_wrapper_title'>Focus areas</h1>
        <div className='focusareas_wrapper_cards'>
            <FocusAreaCard title={titles[0]} bg_img={bg_imgs[0]} paragraph={paragraphs[0]} direction={'forward'}/>
            <br></br>
            <FocusAreaCard title={titles[1]} bg_img={bg_imgs[1]} paragraph={paragraphs[1]} direction={'reverse'}/>
            <br></br>
            <FocusAreaCard title={titles[2]} bg_img={bg_imgs[2]} paragraph={paragraphs[2]} direction={'forward'}/>
            <br></br>
            <FocusAreaCard title={titles[3]} bg_img={bg_imgs[3]} paragraph={paragraphs[3]} direction={'reverse'}/>
            <br></br>
            <FocusAreaCard title={titles[4]} bg_img={bg_imgs[4]} paragraph={paragraphs[4]} direction={'forward'}/>
        </div>
    </div>
  )
}

export default FocusAreas