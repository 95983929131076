import React, { forwardRef } from 'react';
import './Timeline.scss';
import TimelineImages_woodenBases from './../../assets/timelineImages_woodenBases.png';
import TimelineImages_handShakeVector from './../../assets/timelineImages_handShakeVector.png';
import TimelineImages_mainTitle from './../../assets/timelineImages_mainTitle.png';

function Timeline({},ref) {
  return (
    <div className='timeline' ref={ref}>
      <div className='timeline_mobile_maintext_heading'>
        <h1 className='timeline_mobile_maintext_heading_text' >Our timeline</h1>
      </div>
      <div className='timeline_wrapper'>
        <div className='timeline_wrapper_upperrow' >
          <div className='timeline_wrapper_upperrow_firstcard' ></div>
          <div className='timeline_wrapper_upperrow_secondcard' >
            <img src={TimelineImages_woodenBases} className='timeline_wrapper_upperrow_secondcard_image' />
            <h3 className='timeline_wrapper_upperrow_secondcard_years' >In 2018</h3>
            <p className='timeline_wrapper_upperrow_secondcard_text' >
              Woodenbases IVS Created won tender from Harlem and zaanstad communes via Startup In Residence Amsterdam competition. Out of 72 competing companies ours was selected.
            </p>
          </div>
          <div className='timeline_wrapper_upperrow_thirdcard' ></div>
          <div className='timeline_wrapper_upperrow_fourthcard' >
            <img src={TimelineImages_handShakeVector} className='timeline_wrapper_upperrow_secondcard_image' />
            <h3 className='timeline_wrapper_upperrow_secondcard_years' >In 2021 - 2022</h3>
            <p className='timeline_wrapper_upperrow_secondcard_text' >
              IJS Capital changed to an APS firm.IJS Capital started providing consultancy services to Cubestech. Customer TCS and ASML netherlands. Sr. SAP Basis consultant.
            </p>
          </div>
        </div>
        <div className='timeline_wrapper_vectormiddle' >
          <div className='timeline_wrapper_vectormiddle_blueellipses'>
            <div className='timeline_wrapper_vectormiddle_blueellipses_1' >
              <div className='timeline_wrapper_vectormiddle_blueellipses_1_text' >
                01
              </div>
            </div>
            <div className='timeline_wrapper_vectormiddle_blueellipses_2' >
              <div className='timeline_wrapper_vectormiddle_blueellipses_2_text' >
                02
              </div>
            </div>
            <div className='timeline_wrapper_vectormiddle_blueellipses_2' >
              <div className='timeline_wrapper_vectormiddle_blueellipses_3_text' >
                03
              </div>
            </div>
            <div className='timeline_wrapper_vectormiddle_blueellipses_4' >
              <div className='timeline_wrapper_vectormiddle_blueellipses_4_text' >
                04
              </div>
            </div>
          </div>
        </div>
        <div className='timeline_wrapper_bottomrow' >
          <div className='timeline_wrapper_bottomrow_secondcard' >
            <img src={TimelineImages_handShakeVector} className='timeline_wrapper_bottomrow_secondcard_image' />
            <h3 className='timeline_wrapper_bottomrow_secondcard_years' >In 2017</h3>
            <p className='timeline_wrapper_bottomrow_secondcard_text' >
              IJS Capital created as an investment company.            
            </p>
          </div>
          <div className='timeline_wrapper_bottomrow_firstcard' ></div>
          <div className='timeline_wrapper_bottomrow_fourthcard' >
            <img src={TimelineImages_handShakeVector} className='timeline_wrapper_bottomrow_secondcard_image' />
            <h3 className='timeline_wrapper_bottomrow_secondcard_years' >In 2019 - 2020</h3>
            <p className='timeline_wrapper_bottomrow_secondcard_text' >
              Aqua culture monitoring solution            
            </p>
          </div>
          <div className='timeline_wrapper_bottomrow_thirdcard' ></div>
        </div>
      </div>
      <div className='timeline_maintext' >
          <div className='timeline_maintext_heading'>
            <h1 className='timeline_maintext_heading_text' >Our timeline</h1>
            <img src={TimelineImages_mainTitle} />
          </div>
          <div className='timeline_maintext_subtext' >
            <div className='timeline_maintext_subtext_top' >
              <p className='timeline_maintext_subtext_top_text' >
                Since 2017 IJS capital has been providing IOT and software consulting services. Firmware and software development of IOT devices.
              </p>
            </div>
            <div className='timeline_maintext_subtext_bottom' >
              <p className='timeline_maintext_subtext_bottom_text' >
                Sr. SAP Basis and HANA consulting services
              </p>
            </div>
          </div>
      </div>
    </div>
  )
}

export default forwardRef(Timeline);