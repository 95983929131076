import React from 'react';
import HeroSection_image1 from './../../assets/denys-nevozhai-7nrsVjvALnA-unsplash.jpg'
import HeroSection_image2 from './../../assets/heroSection_img2.png';
import HeroSection_image3 from './../../assets/heroSection_img3.png';
import './HeroSection.scss';


function HeroSection() {
  return (
    <div className='herosectio_wrapper'>
        <div className='herosection_wrapper_images'>
          <div className='herosection_wrapper_images_1'></div>
        </div>
        <div className='herosection_wrapper_title'>
            <h1 className='herosection_wrapper_title_heading'>IJS Capital APS</h1>
            <h5 className='herosection_wrapper_title_moto'>Investing and consulting services</h5>
        </div>
    </div>
  )
}

export default HeroSection