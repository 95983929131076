import React, { forwardRef, useEffect,useRef, useState } from 'react';
import './GetInTouch.scss';
import EmailVector from './../../assets/emailVector.png';
import PhoneVector from './../../assets/phoneVector.png';
import axios from 'axios';

function GetInTouch({}, ref) {

  const [emailActive, setEmailActive] = useState(false);
  const [messageActive, setMessageActive] = useState(false);
  const [getInTouch,setGetInTouch] = useState({
    email:"",
    message:""
  })
  const [sumbit,setSumbit] = useState(false);
  const mountedRef = useRef(false);
  console.log(mountedRef.current)

  const handleChange = (e)=>{
    setGetInTouch((prev)=>{
      return{
        ...prev,
        [e.target.name] : e.target.value
      }
    })
  }

    const handleSubmit = async () => {
      try {
        const url = `http://localhost:8000/mail`;
        await axios.post(url, { getInTouch });
        setGetInTouch({
          email:"",
          message:""
        })
      } catch (error) {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          alert("There is something wrong with email");
          return;
        }
      }
    };




  return (
    <div className='getintouch_wrapper' ref={ref}>
        <div className='getintouch_wrapper_form'>
            <h1 className='getintouch_wrapper_form_title'>Contact us</h1>
            <p className='getintouch_wrapper_form_paragraph'> If our products and services interests you please contact us via mail or phone or by filling your details in the form below</p>
            <form onSubmit={(e)=>{e.preventDefault();handleSubmit();}} className='getintouch_wrapper_form_form' >
              <div className='getintouch_wrapper_form_form_email' >
                <label for='emailinput' className={(emailActive)? 'hidden': ' '}>Email: </label>
                <input name='email' type='email' id='emailinput' className='getintouch_wrapper_form_form_email_emailinput' value={getInTouch.email} onChange={handleChange} onClick={() => {setEmailActive(true); setMessageActive(false);}}></input>
              </div>
              <div className='getintouch_wrapper_form_form_message' >
                <label for='messageinput' className={(messageActive)? 'hidden': ' '}>Message: </label>
                <input name='message' type='text' id='messageinput' className='getintouch_wrapper_form_form_message_messageinput' value={getInTouch.message} onChange={handleChange} onClick={() => {setEmailActive(false); setMessageActive(true);}}></input>
              </div>
              <button>
              <span>Send</span>
                <svg viewBox="-5 -5 110 110" preserveAspectRatio="none" aria-hidden="true">
                  <path d="M0,0 C0,0 100,0 100,0 C100,0 100,100 100,100 C100,100 0,100 0,100 C0,100 0,0 0,0"/>
                </svg>
              </button>
            </form>
            <div className='getintouch_wrapper_form_contacts' >
              <div className='getintouch_wrapper_form_contacts_email'>
                <img src={EmailVector} className='getintouch_wrapper_form_contacts_email_vector' />
                <p className='getintouch_wrapper_form_contacts_email_address'>info@ijscapital.com</p>
              </div>
              <div className='getintouch_wrapper_form_contacts_phone'>
                <img src={PhoneVector} className='getintouch_wrapper_form_contacts_phone_vector' />
                <p className='getintouch_wrapper_form_contacts_phone_number'>+45 41151232 </p>
              </div>
            </div>
        </div>
        <div className='getintouch_wrapper_mainimage'></div>
    </div>
  )
}

export default forwardRef(GetInTouch);